<template>
  <v-menu
    ref="menuTime"
    v-model="menuTime"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="model"
    transition="scale-transition"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider v-if="notEmpty" v-slot="{ errors }" :name="label" rules="required">
        <v-text-field
          v-model="formattedTime"
          :label="label"
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          v-bind="attrs"
          :error-messages="errors"
          v-on="on"
        >
        </v-text-field>
      </validation-provider>
      <v-text-field
        v-else
        v-model="formattedTime"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
      >
      </v-text-field>
    </template>
    <v-sheet>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-time-picker v-if="menuTime" v-model="model" full-width> </v-time-picker>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn text color="error" @click="menuTime = false">Cancel</v-btn>
            <v-btn text color="success" @click="close">OK</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-menu>
</template>

<script>
import dateF from '../../services/date.service'

import { required } from 'vee-validate/dist/rules'
import { extend, ValidationProvider } from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  components: {
    ValidationProvider,
  },
  props: ['time', 'label', 'notEmpty'],
  data() {
    return {
      menuTime: false,
      model: false,
    }
  },
  computed: {
    formattedTime() {
      return dateF.formatTime(this.model)
    },
  },
  watch: {
    time(val) {
      this.model = val
    },
  },
  mounted() {
    this.model = this.time
  },
  methods: {
    close() {
      this.menuTime = false
      this.$refs.menuTime.save(this.model)
      this.$emit('update:time', this.model)
    },
  },
}
</script>

<style>
.theme--dark.v-picker__body {
  background-color: var(--v-background-base);
}
.theme--dark.v-time-picker-clock {
  background-color: var(--v-background-base) !important;
}
.theme--dark.v-sheet {
  background-color: var(--v-background-base);
}
</style>
