import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menuTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.model,"transition":"scale-transition","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.model=$event},"update:return-value":function($event){_vm.model=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.notEmpty)?_c('validation-provider',{attrs:{"name":_vm.label,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":"mdi-clock-time-four-outline","readonly":"","error-messages":errors},model:{value:(_vm.formattedTime),callback:function ($$v) {_vm.formattedTime=$$v},expression:"formattedTime"}},'v-text-field',attrs,false),on))]}}],null,true)}):_c(VTextField,_vm._g(_vm._b({attrs:{"label":_vm.label,"prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.formattedTime),callback:function ($$v) {_vm.formattedTime=$$v},expression:"formattedTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTime),callback:function ($$v) {_vm.menuTime=$$v},expression:"menuTime"}},[_c(VSheet,[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[(_vm.menuTime)?_c(VTimePicker,{attrs:{"full-width":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e()],1)],1),_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.menuTime = false}}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"text":"","color":"success"},on:{"click":_vm.close}},[_vm._v("OK")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }